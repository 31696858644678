import React from 'react';

export default function NotFound() {
  return (
    <div>
      <h1>Not Found</h1>
      <h3>404</h3>
    </div>
  );
}
